@import "~bootstrap/scss/bootstrap.scss";
@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss";
$background-color: #fff;

@font-face{
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.otf')
}
body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
