$navbar-background: #44433f;

.ra-navbar {
  background-color: $navbar-background;
  color: #fff;
}

.season-selection {
  background-color: #4f9d24;
  color: #fff;
  font-weight: bold;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  right: 26px;
  cursor: pointer;
  line-height: 40px;
}
