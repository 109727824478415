.launch-btn {
    background-color: #4c9626;
    border: none;
  }

  .launch-btn:disabled {
    background-color: #C2C2C2;
    border: none;
  }
  
  .launch-btn:hover {
    background-color: #65ca33;
    border: none;
  }