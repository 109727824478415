.main-box {
    background-color: #cecece;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    min-height: 100vh;
    margin-top: 5vh;
}

.title-box {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-left: 30px;
    margin-top: 10px;
}

.plot-btn{
    background: #4C9626;
    border-radius: 50%;
    border: none;
    width: 75px;
    height: 75px;
    color: #FFFFFF;
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    left: 165vh;
    position: relative;
    bottom:9vh;
}

.plot-table {
    margin-left: 2%;
    overflow: auto;
    margin-right: 2%;

}

.cardBody {
    background-color: #1D321C;
    border-radius: 20px;
    height: 100%;
}

.logoCard {
    position: relative;
    margin-left: 50px;
    width: 100px;
    display: flex;
    justify-items: center;
    bottom: 60px;
}

.cardTitle{
    text-align: center;
    margin-bottom: 50px;
    size: 24px;
    color: #FFFFFF;
}

.cardText {
    text-align: center;
    color: #FFFFFF;
}