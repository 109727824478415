.background-img{
    background: url("../../assets/images/loginBackgroud.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.login-square{
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 25%;
    margin-top: 10%;
    margin-bottom: 5%;
    padding: 2%;
}
.login-logo{
    display:block;
    margin-left: auto;
    margin-right: auto;
}

.btn-login{
    position:relative;
    margin-top: 20px;    
    display:block;
    margin-left: auto;
    margin-right: auto;
    background-color: #4F9D24;
    width: 100%;
    border: none;
}

.btn-login:hover{
    position:relative;
    margin-top: 20px;    
    display:block;
    margin-left: auto;
    margin-right: auto;
    background-color: #4F9D24;
    width: 100%;
    border: none;
}

.square-text{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.forgot-text{
    text-decoration: none;
    font-size: 13.87px;
    line-height: 21px;
}