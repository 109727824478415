$sidebar-beige: #1D321C;
$sidebar-selected-item: #fff;

@media screen and (min-width: 800px) {
  .ra-sidebar {
    background-color: $sidebar-beige;
      height: 135vh;
    .sidebar {
      background-color: $sidebar-beige;
      height: 135vh;
    }
  
    .sidebar-logo {
      width: 80%;
    }
  
    .sidebar-items {
      margin-top: 20%;
      cursor: pointer;
    }
  
    .sidebar-row {
      height: 50px;
      color: #fff;
      // border-top-left-radius: 30%;
      // border-bottom-left-radius: 30%;
    }
  
    .sidebar-row:hover {
      height: 50px;
      background-color:  #cecece;
    }
  
    .sidebar-nav-item {
      height: 50px;
      color: #fff;
    }
  
    .sidebar-nav-item:hover {
      height: 50px;
      color: #0b0b0b;
      background-color:  #cecece;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      border-top-right-radius: -100px;
    }
  
    .active {
      height: 50px;
      color: #0b0b0b;
      background-color: $sidebar-selected-item;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
  }
  
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .sidebar {
    background-color: $sidebar-beige;
    height: 100vh;
  }

  .sidebar-logo {
    width: 80%;
  }

  .sidebar-items {
    margin-top: 20%;
    cursor: pointer;
  }

  .sidebar-row {
    height: 40px;
    color: #fff;
    // border-top-left-radius: 30%;
    // border-bottom-left-radius: 30%;
  }

  .sidebar-row:hover {
    height: 50px;
    background-color: $sidebar-selected-item;
  }

  .sidebar-nav-item {
    height: 50px;
    color: #fff;
  }

  .sidebar-nav-item:hover {
    height: 50px;
    color: #0b0b0b;
    background-color: $sidebar-selected-item;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: -100px;
  }

  .active {
    height: 50px;
    color: #0b0b0b;
    background-color: $sidebar-selected-item;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
}
