.frist-card-plot {
    background-color: #CECECE;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    min-height: 45vh;
    min-width: 43vh;
    margin-top: 44px;
    margin-bottom: 30px;
  }
  
  .frist-card-text-plot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  #dropdown-basic-plot {
    background: #1D321C;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    border: none;
  }
  
  .second-card-plot {
    background-color: #CECECE;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    min-height: 45vh;
    margin-top: 44px;
    margin-bottom: 30px;
    min-width: 43vh;
  }
  
  .second-card-button-plot {
    background: #4c9626;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #ffffff;
    text-align: center;
    font-size: xx-large;
    margin-left: 88%;
  }
  
  .second-card-text-plot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  
  .second-col-card-plot {
    background: #CECECE;
    border-radius: 20px;
    height: 96%;
    min-width: 50vh;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
  }
  
  .second-col-text-plot {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
  }
  
  .second-col-dropdown-plot {
    position: relative;
    left: 569px;
    bottom: 61px;
    border-radius: 0px 10px;
  }
  
  .card-footer-plot {
    display: flex;
    flex-direction: row;
  }
  
  .frist-box-plot {
    background-color: #168e8e;
    width: 204px;
    height: 125px;
    border-radius: 11.4838px;
    display: flex;
  }
  .frist-box-plot span {
    margin-top: 6px;
    margin-left: 7px;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #ffffff;
  }

  .inputs-btn-plot {
    background-color: #4c9626;
    border: none;
    margin-left: 20px;
  }
  
  .inputs-btn-plot:hover {
    background-color: #65ca33;
    border: none;
  }

  .frist-card-button-plot {
    background: #4c9626;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #ffffff;
    text-align: center;
    font-size: xx-large;
    margin-left: 88%;
  }

  .plot-card {
    background-color: #1D321C;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px
  }

  .custom-dialog{
    background-color: #1D321C;
  }