.main-boxW {
    background-color: #cecece;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    min-width: 100%;
    min-height: 86vh;
}

.title-boxW {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-left: 30px;
    margin-top: 20px;
}

.plot-btnW{
    background: #4C9626;
    border-radius: 50%;
    border: none;
    width: 75px;
    height: 75px;
    color: #FFFFFF;
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    margin-left: 420px;
    position:relative;
    bottom: 20px
}

.inputs-btnW {
    background-color: #4c9626;
    border: none;
    margin-top: 20px;
  }
  
  .inputs-btnW:hover {
    background-color: #65ca33;
    border: none;
    margin-top: 20px;
  }
  