$ra-green: #4C9626;

.bank-account-card {
    border: solid;
    border-color: $ra-green;
    border-width: 3px;
    border-radius: 10px;
    height: 10%;
    margin-top: 5%;
}

.bank-accounts-content {
    overflow: hidden;
    max-height: 10%;
    margin-top: -16%;
}

.bankBtn{
    background: #4c9626;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #ffffff;
    text-align: center;
    font-size: xx-large;
    position: relative;
    left: 98%;
    bottom: 8vh;
}